<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Bevestiging van je emailadres" />
    <b-row>
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je emailadres</h1>
        <p>
          We hebben je account-aanvraag goed ontvangen. Je ontvangt hierover nu
          een bevestigingsmail. Klik op de link in de mail om je aanvraag te
          bevestigen. Hierna moeten we je account ook goedkeuren alvorens je kan
          inloggen. Omdat we afhankelijk zijn van info van jouw lokaal bestuur,
          kan dit proces enkele dagen duren.
        </p>
        <p>
          Heb je vragen over je account? Neem dan contact op met
          <a href="mailto:uitleendienst@vlaamsbrabant.be"
            >uitleendienst@vlaamsbrabant.be</a
          >
        </p>
        <h4>In tussentijd al eens snuisteren door ons aanbod?</h4>
        <router-link class="btn btn-primary mt-3" :to="{ name: 'index' }"
          >Bekijk onze catalogus</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
